<template>
  <div class="hello">
    <div class="ato-content clearfix">
      <div class="ato-list-fl">
        <a class="prev">
          <img style="cursor: pointer" src="../assets/img/annual-metting/pro.png" alt="" class="ato-ope" @click="arrowClick('prev')">
        </a>
        <a class="next">
          <img style="cursor: pointer" src="../assets/img/annual-metting/next.png" alt="" class="ato-ope" @click="arrowClick('next')">
        </a>
      </div>
      <!--          {{areaResearchData}}-->
      <div class="ato-list">
        <el-carousel height="340px" :interval="5000" arrow="never" indicator-position="none" ref="cardShow">
          <el-carousel-item v-for="(iterm, index) in areaResearchData" :key="index">
            <div style="width: 1200px; height: 340px; display: flex;">
              <!-- <img :src="'/admin/'+iterm.cover" alt="" class="ato-logo001"> -->
              <img :src="iterm.picture + '?w=470&h=340'" alt="" class="ato-logo001">
              <div class="msg">
                <h6 id="expertTitle">{{ iterm.title }}</h6>
                <div class="info">
                  {{ iterm.abstractCn }}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <!--        <ul @click="goDetail">-->
        <!--          <li v-for="(iterm, index) in areaResearchData" :key="index">-->
        <!--            <div>-->
        <!--              <img :src="iterm.picture + '?w=470&h=340'" alt="" class="ato-logo001">-->
        <!--              <div class="msg">-->
        <!--                <h6 id="expertTitle">{{ iterm.title }}</h6>-->
        <!--                <div class="info">-->
        <!--                  {{ iterm.abstractCn }}-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </li>-->
        <!--        </ul>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  //   props: {
  //       areaResearchData: {
  //         type: Array,
  //         default: []
  //       }

  //   },
  props: ['areaResearchData'],
  data() {
    return {
      //   areaResearchData: areaResearchData,
      pageName: '欧洲研究'
    }
  },
  watch: {
    areaResearchData(i) {
      //   this.changeData()
    }
  },
  mounted() {
    // setTimeout(function () {
    //   jQuery('.ato-content').slide({
    //     titCell: '.ato-list-fl ul',
    //     mainCell: '.ato-list ul',
    //     autoPage: true,
    //     effect: 'leftLoop',
    //     autoPlay: true,
    //     vis: 1
    //   })
    // }, 5000)
  },
  methods: {
    arrowClick(val) {
      if (val === 'next') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },

    goDetail(e) {
      var _this = this
      //console.log('e.target.children[0].children[1].children[0].innerText', e.target.children[0].children[1].children[0].innerText)
      // getElementById('.expertTitle').innerText)
      // children[0].children[1].children[0].innerText)
      var txt = e.target.children[0].children[1].children[0].innerText
      _this.areaResearchData.forEach(i => {
        if (i.titleCn == txt) {
          // window.open('../../static/pdf/web/viewer.html?file=' + i.attachment)
          //   _this.$router.push({path:'/informationDetail',query:{id: i.id, parentName: _this.pageName, type:'ozyj_jtldoz'}})
        }
      })
    },
    changeData() {
      // this.$nextTick(function () {
      //   jQuery('.ato-content').slide({
      //     titCell: '.ato-list-fl ul',
      //     mainCell: '.ato-list ul',
      //     autoPage: true,
      //     effect: 'leftLoop',
      //     autoPlay: true,
      //     vis: 5
      //   })
      // })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/* 公共样式表css */
$fontSize: 16;
@function px2rem($px) {
  @return ($px/$fontSize) + rem;
}

html,
body {
  color: #333;
  margin: 0;
  height: 100%;
  font-family: 'Myriad Set Pro', 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
}

img {
  border: 0;
}

body {
  background: #fff;
  color: #666;
  font: (px2rem(12))/150% Arial, Verdana, '微软雅黑';
}

html,
body,
div,
dl,
dt,
dd,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
button,
fieldset,
form,
input,
legend,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  // color: #08acee;
}

button {
  outline: 0;
}

img {
  border: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
  outline: none;
}

li {
  list-style: none;
}

.clearfix::after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  visibility: hidden;
}

.clearfix {
}

/* 必要布局样式css */
.ato-content {
  width: 1300px;

  margin: 0 auto;
  // padding-top: 40px;
  padding: 0;
}

.ato-list-fl {
  position: relative;
}

.ato-list-fl .prew {
  position: absolute;
  left: 0;
}

.ato-list-fl .next {
  position: absolute;
  right: 0;
}

.ato-list-fl a {
  width: 34px;
  height: 34px;
  position: relative;
  margin-top: 152px;
  float: left;
  display: block;
  overflow: hidden;
}

.ato-list-fl a img {
  width: 34px;
  height: 34px;
  display: block;
  border: none;
  margin: 0 auto;
  // margin-top: 12px;
}

.ato-list ul {
  // padding-left: 7px !important;
}

.ato-list ul li {
  /* margin-top:30px; */
  // width: px2rem(250);
  // height: px2rem(180);
  width: 1200px;
  height: 340px;
  float: left;
  margin-left: 0px;
  margin-right: 0;
  text-align: center;
  // background-color: #F2F9F5;
  -webkit-transition: height 300ms;
  -moz-transition: height 300ms;
  -ms-transition: height 300ms;
  -o-transition: height 300ms;
  transition: height 300ms;
  position: relative;
  top: px2rem(0);
  left: -px2rem(10);
  cursor: pointer;
}

.ato-list ul li:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* box-shadow: 0 0px 2px rgba(0,0,0,0.24); */
}

//.ato-list ul li > div {
//  // width: px2rem(250);
//  width: 1200px;
//  height: 340px;
//  display: flex;
//}

.ato-list div img {
  // width: px2rem(250);
  // height: px2rem(150);
  width: 470px;
  height: 340px;
  border: none;
  display: block;
  margin: 0;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.36);
}

.ato-list div .msg {
  width: 730px;
  height: 340px;
  background: #f3f1f1;
  padding: 0 30px;
  display: block;

  h6 {
    width: 670px;
    padding-top: 34px;
    font-size: 32px;
    font-family: Source Han Serif CN;
    font-weight: bold;
    color: #2b221b;
    line-height: 32px;
    padding-bottom: 35px;
    text-align: left;
  }

  .info {
    width: 670px;
    min-height: 170px;
    padding: 46px 0;
    position: relative;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2b221b;
    line-height: 24px;
    box-sizing: border-box;
    text-align: left;
    z-index: 100;

    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;

    cursor: auto;
  }

  .info::before {
    content: '';
    z-index: 120;
    width: 42px;
    height: 33px;
    position: absolute;
    left: 0;
    top: 0;
    background: url(../assets/img/annual-metting/img-quotation-L.png);
    background-size: 100% 100%;
    cursor: auto;
  }

  .info::after {
    content: '';
    width: 42px;
    height: 33px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: url(../assets/img/annual-metting/img-quotation-R.png);
    background-size: 100% 100%;
    cursor: auto;
  }
}

.ato-list  .msg h6 {
}

// .ato-list ul li div img:hover {
//     // transform: scale(1.2);
//     width: 1200px;
//     height: 340px;
// }

.ato-list h3 {
  text-align: center;
  font-size: px2rem(17);
  line-height: px2rem(30);
  font-weight: normal;
  color: #333;
  font-weight: 500;
}

.ato-list  p {
  margin: 0;
  text-align: left;
  font-size: px2rem(13);
  color: #8e8e8e;
  line-height: px2rem(25);
  padding: 0 px2rem(22) px2rem(20) px2rem(22);
}

.ato-title {
  width: px2rem(266);
  margin-top: px2rem(130);
  padding-right: px2rem(20);
}

.ato-title h2 {
  font-size: px2rem(24);
  line-height: px2rem(33);
  color: #333;
  padding: 0;
  margin: 0;
  font-weight: inherit;
}

.ato-title p {
  margin-top: px2rem(10);
  font-size: px2rem(14);
  color: #666;
  line-height: px2rem(26);
}

.ato-list {
  width: 1200px;
  height: 340px;
  // margin: 0 auto;
  margin: 0px;
  margin-left: 50px;
  overflow: hidden;
  margin-bottom: px2rem(10);
}

.ato-list ul li:hover .ato-logo001 {
  top: -px2rem(5);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
</style>
