<template>
    <div class="memory-swiper-img-component">
        <div id="demo8">

            <table align="left" cellspace="0" border="0" cellpadding="0">

                <tr  @click="openImg">

                    <td id="demo5" valign="top">

                        <table border="0" cellpadding="0" cellspacing="0">

                            <tr>

                                <td>

                                    <table cellSpacing="0" border="0" cellPadding="7">

                                        <tbody>

                                            <tr align="middle">
                                                <td style="padding: 0 8px;" v-for="item in imgMemoryList" :key="item.id">

                                                    <div>
                                                        <img class="expert-pic" :src="item.picture + '?w=480&h=293'" style="width: 480px; height: 293px;"/>
                                                        <p style="display: none;">{{item.id}}</p>
                                                            <!-- <el-image 
                                                              style="width: 480px; height: 293px;"
                                                              :src="item.picture"
                                                              :preview-src-list="[item.picture]">
                                                            </el-image> -->

                                                    </div>
                                                </td>
                                                <!-- <td style="padding: 0 8px;">

                                                    <div>
                                                        <img src="../assets/img/annual-metting/005.jpg"
                                                            style="width: 480px; height: 293px;" />

                                                    </div>
                                                </td>
                                                <td style="padding: 0 8px;">

                                                    <div>
                                                        <img src="../assets/img/annual-metting/005.jpg"
                                                            style="width: 480px; height: 293px;" />

                                                    </div>
                                                </td>
                                                <td style="padding: 0 8px;">

                                                    <div>
                                                        <img src="../assets/img/annual-metting/005.jpg"
                                                            style="width: 480px; height: 293px;" />
                                                    </div>
                                                </td>
                                                <td style="padding: 0 2px 0 8px;">

                                                    <div>
                                                        <img src="../assets/img/annual-metting/005.jpg"
                                                            style="width: 480px; height: 293px;" />
                                                    </div>
                                                </td> -->

                                            </tr>

                                        </tbody>

                                    </table>

                                </td>

                            </tr>

                        </table>

                    </td>

                    <td id="demo3" valign="top"></td>

                </tr>

            </table>

        </div>
        

        </div>
</template>

<script>
export default {
    props: ['imgMemoryData'],
    data() {
        return {
            imgMemoryList: [],
            
        }
    },
    watch: {
        imgMemoryData(newVal, oldVal) {
            this.imgMemoryList = newVal
            setTimeout(this.init, 1500)
        }
    },
    mounted() {},
    methods: {
        openImg(e) {
            // console.log('e.target.innerHTML', )
            this.$emit('openImg', e.target.currentSrc.split('?')[0], e.target.parentNode.children[1].innerHTML)
        },
        init() {
            var speed = 20

            //数值越大速度越慢

            demo3.innerHTML = demo5.innerHTML

            function Marquee() {
                if (demo3.offsetWidth - demo8.scrollLeft <= 0) demo8.scrollLeft -= demo5.offsetWidth
                else {
                    demo8.scrollLeft++
                }
            }

            let MyMar = setInterval(Marquee, speed)

            demo8.onmouseover = function() {
                clearInterval(MyMar)
            }
            demo8.onmouseout = function() {
                MyMar = setInterval(Marquee, speed)
            }
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.memory-swiper-img-component {
    margin-top: 14px;
    height: 296px;
    width: 100%;
    position: relative;
    #demo8 {
        overflow: hidden;
        height: 100%;
        width: 100%;
        color: #ffffff;
        /* margin: 0 auto; */
        position: absolute;
        top: 0;
        left: 0;
        // transform: translate(-50%, -50%);
    }
    table,
    tbody {
        margin: 0;
        padding: 0;
    }
    tr {
        margin: 0;
        padding: 0;
    }
    td {
        padding: 0;
    }
}
</style>