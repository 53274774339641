<template>
    <div class="video-component">
        <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
        <div id="psVideo" class="psVideo">
            <video :src="videoUrl" :poster="videoImg" class="my-psVideo" id="my-video" preload="metadata">
                    <source :src="videoUrl" type="video/mp4">
            </video>
            <div class="psVideo-loading">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <!-- <div class="psVideo-play-one"></div> -->
            <div class="psVideo-shade psVideo-shade-on"
                style="background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1));">
                <div class="psVideo-play-footer">
                    <div class="psVideo-progress">
                        <span class="psVideo-timeBar"></span>
                    </div>
                    <div class="psVideo-play-btn psVideo-stop"></div>
                    <div class="psVideo-time">
                        <span id="currentTime">00:00</span>
                        <span>/</span>
                        <span id="duration">00:00</span>
                    </div>
                    <!-- <div class="psVideo-dan">
                    <input type="text" class="psVideo-dan-input" placeholder="点击输入弹幕,回车键发送" />
                    <span class="psVideo-dan-btn">发送</span>
                </div> -->
                    <div class="psVideo-right-btn">
                        <div class="psVideo-btn all"></div>
                        <!-- <div class="psVideo-btn loop" id="loop"></div> -->
                        <!-- <div class="psVideo-btn set">
                        <div class="set-list">
                            <div class="play-speed">播放速度</div>
                            <div class="play-speed-list">
                                <span id="speed05Btn">X0.5</span>
                                <span class="moren" id="speed1Btn">X1</span>
                                <span id="speed2Btn">X2</span>
                            </div>
                        </div>
                        <div class="konge1"></div>
                    </div> -->
                        <!-- <div class="psVideo-btn huazhi">自动</div> -->
                        <div class="psVideo-btn sound" id="soundBtn">
                            <div class="sound-list">
                                <div class="sound-number">90</div>
                                <div class="volume">
                                    <span class="volumeBar"></span>
                                </div>
                            </div>
                            <div class="konge"></div>
                        </div>
                    </div>
                    <!-- <div class="psVideo-btn download" id="downBtn" @click="goDown">
                      
                    </div> -->
                </div>
            </div>
            <!-- <div class="player-list">
            <div class="player-list-bk">
                <div class="player-list-head">播放列表</div>
                <div class="player-list-cotent">
                    <div class="player-list-box">
                        <div class="player-list-video video-now">炉石传说</div>
                        <div class="player-list-video">突然之间</div>
                        <div class="player-list-video">进阶</div>
                        <div class="player-list-video">渐渐</div>
                        <div class="player-list-video">年少有为</div>
                        <div class="player-list-video">爱久见人心</div>
                        <div class="player-list-video">盗将行</div>
                        <div class="player-list-video">Lemon</div>
                        <div class="player-list-video">我的梦</div>
                    </div>
                </div>
            </div>
        </div> -->
        </div>
    </div>
</template>

<script>
import jQuery from 'jquery'
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'Home',
    components: {},
    props: ['videoCover', 'videoPath'],
    data() {
        return {
            videoUrl: '',
            videoImg: ''
        }
    },
    watch: {
        videoPath(newVal, oldVal) {
            // this.videoUrl = require('../assets/img/annual-metting/12.mp4')
            // newVal
            this.videoUrl = newVal
            this.videoImg = this.videoCover
            this.init()
        }
    },
    mounted() {
        // this.init()
    },
    methods: {
        goDown() {
            let x = this.videoUrl.replace('/admin', '')
            let y = '/admin/common/download/resource?resource=' + x
            // window.open('/admin/common/download/resource?resource=' + x)
            var $a = document.createElement('a')
            $a.setAttribute('href', y)
            // $a.setAttribute('download', title + ' ' + _this.$route.query.pic + (ind + 1) + '.png')
            $a.click()
        },
        init() {
            ;(function($) {
                var video = $('#my-video')
                var loop = false
                var qieh = 1
                var videoIndex = 0
                var videoProp = 0
                var ddd = document.getElementById('video-div')
                var full = false
                var timer
                var hidding = false
                var isOn = true
                // var videoListLen = $('.player-list-video').length
                var videoSpeed = 1
                // var videoListAll = new Array('https://blz-videos.nosdn.127.net/1/HearthStone/f6cd63b590d416821d3e27e0.mp4', 'http://hd.yinyuetai.com/uploads/videos/common/EB170169C381ACA65BDFA873976313F3.mp4', 'http://hc.yinyuetai.com/uploads/videos/common/FA6B0169A324DD75A87E1C84F4B31399.mp4', 'http://hc.yinyuetai.com/uploads/videos/common/7AD001644D9382E54FD583466B66EAC8.mp4', 'http://hd.yinyuetai.com/uploads/videos/common/BB87016532900E3929D35E14BF2C6C40.mp4', 'http://hc.yinyuetai.com/uploads/videos/common/12D80162AA2DB6AAF891A9F4591D3829.mp4', 'http://hc.yinyuetai.com/uploads/videos/common/F7580167115F337E6ECB7448886C5700.mp4', 'http://hd.yinyuetai.com/uploads/videos/common/FDB801694C7FC529294DB399983B4356.mp4', 'http://hc.yinyuetai.com/uploads/videos/common/4454016540FBBFE757F1EBA66259FAEF.mp4')
                var danId = 0
                var clickFlag = null
                var vedioError = null

                $('.psVideo-timeBar').css('width', 0)

                //开始时暂停
                video[0].pause()
                $('.psVideo-play-btn')
                    .removeClass('psVideo-play')
                    .addClass('psVideo-stop')

                function qiehuan() {
                    // video[0].src = videoListAll[videoIndex]
                    $('.psVideo-timeBar').css('width', 0)
                    video[0].play()
                    $('.psVideo-play-btn')
                        .removeClass('psVideo-stop')
                        .addClass('psVideo-play')
                    // $('.psVideo-play-one').hide()
                }

                // 读取初始时间
                function timeFormat(seconds) {
                    var minite = Math.floor(seconds / 60)
                    if (minite < 10) {
                        minite = '0' + minite
                    }
                    var second = Math.floor(seconds % 60)
                    if (second < 10) {
                        second = '0' + second
                    }
                    return minite + ':' + second
                }
                //更新时间进度条
                function updateProgress(x) {
                    if (!full || !hidding) {
                        var progress = $('.psVideo-progress')
                        var position = x - progress.offset().left
                        var percentage = (100 * position) / progress.width()
                        if (percentage > 100) {
                            percentage = 100
                        }
                        if (percentage < 0) {
                            percentage = 0
                        }
                        // console.log('percentage', percentage)
                        $('.psVideo-timeBar').css('width', percentage + '%')
                        video[0].currentTime = (video[0].duration * percentage) / 100
                        // console.log('video[0].currentTime', video[0].currentTime)
                    }
                }
                // 拖拽时间进度条
                function enableProgressDrag() {
                    if (!full || !hidding) {
                        var progressDrag = false
                        $('.psVideo-progress').on('mousedown', function(e) {
                            progressDrag = true
                            updateProgress(e.pageX)
                        })
                        $(document).on('mouseup', function(e) {
                            if (progressDrag) {
                                progressDrag = false
                                updateProgress(e.pageX)
                            }
                        })
                        $(document).on('mousemove', function(e) {
                            if (progressDrag) {
                                updateProgress(e.pageX)
                            }
                        })
                    }
                }
                // 控制栏展示消失
                function showControl(shouldShow) {
                    if (shouldShow) {
                        $('.psVideo-shade')
                            .removeClass('psVideo-shade-off')
                            .addClass('psVideo-shade-on')
                        $('.player-list')
                            .removeClass('player-list-off')
                            .addClass('player-list-on')
                    } else {
                        $('.psVideo-shade')
                            .removeClass('psVideo-shade-on')
                            .addClass('psVideo-shade-off')
                        $('.player-list')
                            .removeClass('player-list-on')
                            .addClass('player-list-off')
                    }
                }
                function fillShow() {
                    if (full) {
                        if (hidding) {
                            hidding = false
                        }
                        if (timer) {
                            clearTimeout(timer)
                            timer = null
                        }
                        $('.psVideo-shade')
                            .removeClass('psVideo-shade-off')
                            .addClass('psVideo-shade-on')
                        $('.player-list')
                            .removeClass('player-list-off')
                            .addClass('player-list-on')
                        video.attr('style', 'cursor:pointer')
                        if (isOn) {
                            timer = setTimeout(function() {
                                hidding = true
                                $('.psVideo-shade')
                                    .removeClass('psVideo-shade-on')
                                    .addClass('psVideo-shade-off')
                                $('.player-list')
                                    .removeClass('player-list-on')
                                    .addClass('player-list-off')
                                video.attr('style', 'cursor:none')
                            }, 2000)
                        }
                    }
                }
                //声音按钮
                function soundAndMute() {
                    if (!full || !hidding) {
                        if (video[0].muted) {
                            video[0].muted = false
                            $('#soundBtn')
                                .removeClass('jinyin')
                                .addClass('sound')
                            $('.volumeBar').css('height', (1 - video[0].volume) * 100 + '%')
                            $('.sound-number').text(parseInt(video[0].volume * 100))
                        } else {
                            video[0].muted = true
                            $('#soundBtn')
                                .removeClass('sound')
                                .addClass('jinyin')
                            $('.sound-number').text(0)
                            $('.volumeBar').css({
                                height: '50px'
                            })
                        }
                    }
                }
                //声音拖拽
                function enableSoundDrag() {
                    if (!full || !hidding) {
                        var volumeDrag = false
                        $('.volume').on('mousedown', function(e) {
                            volumeDrag = true
                            updateVolume(e.pageY)
                            video[0].muted = false
                            $('#soundBtn')
                                .removeClass('jinyin')
                                .addClass('sound')
                        })
                        $(document).on('mouseup', function(e) {
                            if (volumeDrag) {
                                volumeDrag = false
                                updateVolume(e.pageY)
                            }
                        })
                        $(document).on('mousemove', function(e) {
                            if (volumeDrag) {
                                updateVolume(e.pageY)
                            }
                        })
                    }
                }
                // 更新声音
                function updateVolume(y, vol) {
                    if (!full || !hidding) {
                        var volume = $('.volume')
                        var soundTop = y - volume.offset().top
                        var percentage
                        if (vol) {
                            percentage = 100 - vol * 100
                        } else {
                            var position = soundTop
                            percentage = (100 * position) / 50
                        }
                        if (percentage > 100) {
                            $('#soundBtn')
                                .removeClass('sound')
                                .addClass('jinyin')
                            percentage = 100
                        } else {
                            $('#soundBtn')
                                .removeClass('jinyin')
                                .addClass('sound')
                        }
                        if (percentage < 0) {
                            percentage = 0
                        }
                        percentage = parseInt(percentage)
                        $('.sound-number').text(100 - percentage)
                        $('.volumeBar').css('height', percentage + '%')
                        video[0].volume = (100 - percentage) / 100
                    }
                }
                //开始暂停
                function playAndPause() {
                    if (video[0].paused || video[0].ended) {
                        // console.log('开始')
                        video[0].play()
                        $('.psVideo-play-btn')
                            .removeClass('psVideo-stop')
                            .addClass('psVideo-play')
                        // $('.psVideo-play-one').hide()
                        playSpeed(videoSpeed)
                        $('.psVideo-dan-all').css('animation-play-state', 'running')
                    } else {
                        // console.log('暂停')
                        video[0].pause()
                        $('.psVideo-play-btn')
                            .removeClass('psVideo-play')
                            .addClass('psVideo-stop')
                        // $('.psVideo-play-one').show()
                        $('.psVideo-dan-all').css('animation-play-state', 'paused')
                    }
                }
                //播放速度
                function playSpeed(speed) {
                    if (!full || !hidding) {
                        if (speed == 1) {
                            $('#speed05Btn').removeClass('moren')
                            $('#speed2Btn').removeClass('moren')
                            $('#speed1Btn').addClass('moren')
                            videoSpeed = 1
                        } else if (speed == 2) {
                            $('#speed05Btn').removeClass('moren')
                            $('#speed1Btn').removeClass('moren')
                            $('#speed2Btn').addClass('moren')
                            videoSpeed = 2
                        } else if (speed == 0.5) {
                            $('#speed1Btn').removeClass('moren')
                            $('#speed2Btn').removeClass('moren')
                            $('#speed05Btn').addClass('moren')
                            videoSpeed = 0.5
                        }
                        video[0].playbackRate = speed
                    }
                }
                //循环播放
                function isloop() {
                    if (!full || !hidding) {
                        if (!loop) {
                            $('#loop')
                                .removeClass('loop')
                                .addClass('loop-ture')
                            video[0].loop = true
                            loop = !loop
                        } else {
                            $('#loop')
                                .removeClass('loop-ture')
                                .addClass('loop')
                            video[0].loop = false
                            loop = !loop
                        }
                    }
                }

                function danOn() {
                    var danText = $('.psVideo-dan-input').val()
                    var zz = '^[ ]+$' //正则判断是否全是空格
                    var isK = new RegExp(zz)
                    if (danText && !isK.test(danText)) {
                        var danIdNow = 'dan' + danId
                        if (full) {
                            var danDom = "<span class='psVideo-dan-all psVideo-dan-value-full' id='" + danIdNow + "'</span>"
                        } else {
                            var danDom = "<span class='psVideo-dan-all psVideo-dan-value' id='" + danIdNow + "'</span>"
                        }
                        $('.psVideo').prepend(danDom)
                        if (danId % 3 == 1) {
                            $('#' + danIdNow).css('margin-top', '30px')
                        } else if (danId % 3 == 2) {
                            $('#' + danIdNow).css('margin-top', '60px')
                        }
                        if (video[0].paused || video[0].ended) {
                            $('.psVideo-dan-all').css('animation-play-state', 'paused')
                        } else {
                            $('.psVideo-dan-all').css('animation-play-state', 'running')
                        }
                        $('.psVideo-dan-all').on('animationend', function() {
                            $('#' + this.id).remove()
                        })
                        danId += 1
                        $('#' + danIdNow).text(danText)
                        $('.psVideo-dan-input').val('')
                    }
                }
                function toFull() {
                    $('.psVideo').addClass('full')
                    video.addClass('full')
                    $('.psVideo-dan').addClass('psVideo-dan-full')
                    $('.psVideo-dan-input').addClass('psVideo-dan-input-full')
                    $('.psVideo-shade').addClass('psVideo-shade-full')
                    timer = setTimeout(function() {
                        hidding = true
                        $('.psVideo-shade')
                            .removeClass('psVideo-shade-on')
                            .addClass('psVideo-shade-off')
                        $('.player-list')
                            .removeClass('player-list-on')
                            .addClass('player-list-off')
                        video.attr('style', 'cursor:none')
                    }, 2000)
                }
                function outFull() {
                    clearTimeout(timer)
                    video.removeClass('full')
                    $('.psVideo').removeClass('full')
                    $('.psVideo-shade').removeClass('psVideo-shade-full')
                    $('.psVideo-shade')
                        .removeClass('psVideo-shade-off')
                        .addClass('psVideo-shade-on')
                    $('.player-list')
                        .removeClass('player-list-off')
                        .addClass('player-list-on')
                    video.attr('style', 'cursor:pointer')
                    $('.psVideo-dan').removeClass('psVideo-dan-full')
                    $('.psVideo-dan-input').removeClass('psVideo-dan-input-full')
                }
                //全屏
                function doOnClick() {
                    if (clickFlag) {
                        clickFlag = clearTimeout(clickFlag)
                    }
                    clickFlag = setTimeout(function() {
                        // console.log('全屏')
                        playAndPause()
                    }, 300)
                }
                function doOnDblClick() {
                    if (clickFlag) {
                        clickFlag = clearTimeout(clickFlag)
                    }
                    launchFullScreen()
                }
                function keyTime(key) {
                    if (key == 39) {
                        var keyCurrentTime = video[0].currentTime + 10
                        var keyDuration = video[0].duration
                        var keyPercent = (100 * keyCurrentTime) / keyDuration
                        $('.psVideo-timeBar').css('width', keyPercent + '%')
                        $('#currentTime').text(timeFormat(keyCurrentTime))
                        video[0].currentTime = keyCurrentTime
                    }
                    if (key == 37) {
                        // console.log(video[0].currentTime)
                        var keyCurrentTime = video[0].currentTime - 10
                        // console.log(keyCurrentTime)
                        var keyDuration = video[0].duration
                        var keyPercent = (100 * keyCurrentTime) / keyDuration
                        $('.psVideo-timeBar').css('width', keyPercent + '%')
                        $('#currentTime').text(timeFormat(keyCurrentTime))
                        video[0].currentTime = keyCurrentTime
                    }
                }
                //全屏
                function launchFullScreen() {
                    if (!full || !hidding) {
                        // var element = document.documentElement
                        var element = document.getElementById('my-video')
                        if (!full) {
                            if (element.requestFullScreen) {
                                element.requestFullScreen()
                            } else if (element.mozRequestFullScreen) {
                                element.mozRequestFullScreen()
                            } else if (element.webkitRequestFullScreen) {
                                element.webkitRequestFullScreen()
                            } else if (element.msRequestFullscreen) {
                                element.msRequestFullscreen()
                            }
                        } else {
                            if (document.exitFullscreen) {
                                document.exitFullscreen()
                            } else if (document.msExitFullscreen) {
                                document.msExitFullscreen()
                            } else if (document.mozCancelFullScreen) {
                                document.mozCancelFullScreen()
                            } else if (document.webkitCancelFullScreen) {
                                document.webkitCancelFullScreen()
                            }
                        }
                    }
                }
                //监听退出全屏事件
                document.addEventListener('fullscreenchange', function(e) {
                    if (!full) {
                        toFull()
                    } else {
                        outFull()
                    }
                    full = !full
                })
                document.addEventListener('mozfullscreenchange', function(e) {
                    if (!full) {
                        toFull()
                    } else {
                        outFull()
                    }
                    full = !full
                })
                document.addEventListener('webkitfullscreenchange', function(e) {
                    if (!full) {
                        toFull()
                    } else {
                        outFull()
                    }
                    full = !full
                })
                document.addEventListener('MSFullscreenChange', function(e) {
                    if (!full) {
                        toFull()
                    } else {
                        outFull()
                    }
                    full = !full
                })

                updateVolume(0, 0.9) // 初始化声音
                video.on('loadedmetadata', function() {
                    $('#currentTime').text(timeFormat(0))
                    $('#duration').text(timeFormat(video[0].duration))
                    enableProgressDrag()
                    enableSoundDrag()
                    playSpeed(videoSpeed)
                })
                video.on('timeupdate', function() {
                    var currentTime = video[0].currentTime
                    var duration = video[0].duration
                    var percent = (100 * currentTime) / duration
                    $('.psVideo-timeBar').css('width', percent + '%')
                    $('#currentTime').text(timeFormat(currentTime))
                })
                video.on('canplay', function() {
                    $('.psVideo-loading').fadeOut(100)
                })
                video.on('waiting', function() {
                    $('.psVideo-loading').fadeIn(100)
                })
                video.on('ended', function() {
                    if (!loop) {
                        videoIndex += 1
                    }
                    // if (videoIndex == videoListLen) {
                    //     videoIndex = 0
                    // }
                    // for (var i = 0; i < videoListLen; i++) {
                    //     $('.player-list-video')
                    //         .eq(i)
                    //         .removeClass('video-now')
                    // }
                    // $('.player-list-video')
                    //     .eq(videoIndex)
                    //     .addClass('video-now')
                    qiehuan()
                })

                $('.psVideo').hover(
                    function() {
                        showControl(true)
                    },
                    function() {
                        showControl(false)
                    }
                )
                // 判断鼠标是否在控制区，进入和出去改变isOn值，来触发是否开启延时函数
                $('.psVideo-shade').hover(
                    function() {
                        isOn = false
                    },
                    function() {
                        isOn = true
                    }
                )
                $('.player-list').hover(
                    function() {
                        isOn = false
                    },
                    function() {
                        isOn = true
                    }
                )
                $('body').on('mousemove', function() {
                    fillShow()
                })
                // $('.psVideo-play-one').on('click', function() {
                //     playAndPause()
                // })
                // $('.psVideo-play-btn').on('click', function() {
                //     console.log('点击1')
                //     if (!full || !hidding) {
                //         console.log('点击2')
                //         playAndPause()
                //     }
                // })
                $('.psVideo-play-btn').unbind('click').click(function(){
                    if (!full || !hidding) {
                        // console.log('点击2')
                        playAndPause()
                    }
                })
                $('.all').on('click', launchFullScreen)
                $('#currentTime').text(timeFormat(0))
                $('#duration').text(timeFormat(video[0].duration))
                enableProgressDrag()
                enableSoundDrag()
                playSpeed(videoSpeed)
                $('#speed1Btn').on('click', function() {
                    playSpeed(1)
                })
                $('#speed2Btn').on('click', function() {
                    playSpeed(2)
                })
                $('#speed05Btn').on('click', function() {
                    playSpeed(0.5)
                })
                //  阻止事件冒泡
                // $('.sound-list').bind('click', function(event) {
                //     event.stopPropagation()
                // })
                // $('.konge').bind('click', function(event) {
                //     event.stopPropagation()
                // })
                // $('#soundBtn').on('click', soundAndMute)
                // $('#loop').on('click', isloop)
                // video.on('click', function() {
                //     doOnClick()
                // })
                $('.sound-list').unbind('click').click(function(event){
                    event.stopPropagation()
                })
                $('.konge').unbind('click').click(function(event){
                    event.stopPropagation()
                })
                $('#soundBtn').unbind('click').click(function(){
                    soundAndMute
                })
                $('#loop').unbind('click').click(function(){
                    isloop
                })
                video.unbind('click').click(function(){
                    doOnClick()
                })

                video.on('dblclick', function() {
                    doOnDblClick()
                })
                $('.psVideo-dan-btn').on('click', function() {
                    danOn()
                })
                $(window).keypress(function(e) {
                    var isFocus = $('.psVideo-dan-input').is(':focus')
                    var isControl = $('.psVideo-shade').hasClass('psVideo-shade-off')
                    if (e.keyCode == 0 || e.keyCode == 32) {
                        if (!isFocus || isControl) {
                            console.log('keypress')
                            playAndPause()
                        }
                    }
                    if (e.keyCode == 13) {
                        if (isFocus) {
                            danOn()
                        }
                    }
                })
                $(window).keydown(function(e) {
                    keyTime(e.keyCode)
                })
                // $('.player-list-video').on('click', function() {
                //     if (!full || !hidding) {
                //         videoIndex = $(this).index()
                //         for (var i = 0; i < videoListLen; i++) {
                //             $('.player-list-video')
                //                 .eq(i)
                //                 .removeClass('video-now')
                //         }
                //         $(this).addClass('video-now')
                //         qiehuan()
                //     }
                // })
            })(jQuery)
        }
    }
}
</script>

<style lang="scss" scoped>
/* * {
    margin: 0;
    padding: 0;
    border: none;
    font-family: 微软雅黑;
}

html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden;
} */

.psVideo {
    width: 1200px;
    position: relative;
    /* margin: 20px auto; */
    text-align: center;
    transition: all 0.5s;
    overflow: hidden;
}

.my-psVideo {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 480px;
    cursor: pointer;
    transition: all 0.5s;
    background: black;
}

.full {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: black;
}

// .psVideo-play-one {
//     width: 70px;
//     height: 70px;
//     background: url('../assets/img/annual-metting/video/playone.png') no-repeat;
//     opacity: 0.7;
//     background-size: cover;
//     position: absolute;
//     bottom: 70px;
//     right: 60px;
//     cursor: pointer;
// }

.psVideo-play-footer {
    position: absolute;
    width: 100%;
    height: 36px;
    bottom: 0;
}

.psVideo-shade {
    position: absolute;
    height: 60px;
    width: 100%;
    bottom: 4px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    transition: opacity 0.3s;
}

.psVideo-shade-full {
    bottom: 0;
}

.psVideo-shade-on {
    opacity: 1;
}

.psVideo-shade-off {
    opacity: 0;
}

.psVideo-play-footer .psVideo-play-btn {
    width: 23px;
    height: 23px;
    float: left;
    margin-left: 3%;
    cursor: pointer;
    z-index: 3;
}

.psVideo-stop {
    background: url('../assets/img/annual-metting/video/stop.png') no-repeat;
    background-size: cover;
}

.psVideo-play {
    background: url('../assets/img/annual-metting/video/play.png') no-repeat;
    background-size: cover;
}

.psVideo-time {
    float: left;
    font-size: 15px;
    line-height: 23px;
    color: white;
    margin-left: 10px;
    z-index: 2;
}

.psVideo-dan {
    position: absolute;
    margin-left: 230px;
    margin-top: -1px;
    z-index: 10;
    transition: all 0.5s;
}

.psVideo-dan-input {
    height: 25px;
    background: rgba(245, 245, 245, 0.8);
    line-height: 25px;
    width: 200px;
    float: left;
    border-right: 1px solid cadetblue;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    outline: none;
    text-indent: 2px;
}

.psVideo-dan-btn {
    display: inline-block;
    height: 25px;
    font-size: 15px;
    width: 50px;
    text-align: center;
    line-height: 25px;
    float: left;
    color: #454545;
    font-weight: bold;
    background: rgba(245, 245, 245, 0.9);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
}

.psVideo-dan-value {
    position: absolute;
    z-index: 20;
    color: white;
    font-size: 25px;
    font-weight: bold;
    white-space: nowrap;
    animation: danOnFull 7s linear;
    transform: translateX(-100%);
}

.psVideo-dan-value-full {
    position: absolute;
    z-index: 20;
    color: white;
    font-size: 25px;
    font-weight: bold;
    white-space: nowrap;
    animation: danOnFull 10s linear;
    transform: translateX(-100%);
}

@keyframes danOn {
    from {
        left: 100%;
        transform: translateX(0);
    }
    to {
        left: 0;
        transform: translateX(-100%);
    }
}

@keyframes danOnFull {
    from {
        left: 100%;
        transform: translateX(0);
    }
    to {
        left: 0;
        transform: translateX(-100%);
    }
}

.psVideo-dan-input-full {
    width: 300px;
}

.psVideo-dan-full {
    margin-left: calc(50% - 175px);
}

.psVideo-right-btn {
    float: right;
    margin-right: 3%;
    z-index: 4;
}

.psVideo-btn {
    width: 23px;
    height: 23px;
    float: right;
    margin-left: 20px;
    cursor: pointer;
}

.all {
    background: url('../assets/img/annual-metting/video/all.png') no-repeat;
    background-size: cover;
}

.loop {
    background: url('../assets/img/annual-metting/video/loop.png') no-repeat;
    background-size: cover;
}

.loop-ture {
    background: url('../assets/img/annual-metting/video/1loop.png');
    background-size: cover;
}

.set {
    position: relative;
    background: url('../assets/img/annual-metting/video/set.png') no-repeat;
    background-size: cover;
}

.set-list {
    display: none;
    background: black;
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 40px;
    left: -85px;
    opacity: 0.7;
    border-radius: 4px;
}

.konge1 {
    display: none;
    position: absolute;
    width: 200px;
    height: 17px;
    bottom: 23px;
    left: -85px;
    text-align: center;
    z-index: 4;
}

.set:hover .set-list,
.set:hover .konge1 {
    display: block;
}

.play-speed {
    color: white;
    font-weight: bolder;
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
}

// .play-speed-list {
//     padding-top: 5px;
//     font-weight: bolder;
//     font-size: 17px;
//     color: azure;
//     text-align: center;
// }

// .play-speed-list span {
//     margin-left: 15px;
//     margin-right: 15px;
// }

// .play-speed-list span:hover {
//     color: #7969ff;
// }

.moren {
    color: #7969ff;
}

.huazhi {
    width: 50px;
    font-size: 15px;
    line-height: 23px;
    color: white;
}

.sound {
    position: relative;
    background: url('../assets/img/annual-metting/video/sound.png') no-repeat;
    background-size: cover;
}

.download {
    position: relative;
    background: url('../assets/img/annual-metting/video/down-btn.png') no-repeat;
    background-size: cover;
    opacity: 0.8;
}

.jinyin {
    position: relative;
    background: url('../assets/img/annual-metting/video/jinyin.png') no-repeat;
    background-size: cover;
}

.psVideo-progress {
    width: 95%;
    background: white;
    height: 2px;
    position: absolute;
    bottom: 50px;
    left: 2.5%;
    cursor: pointer;
    transition: bottom 0.1s;
    border-radius: 3px;
}

.psVideo-progress:hover {
    height: 6px;
    bottom: 48px;
}

.psVideo-progress .psVideo-timeBar {
    height: 100%;
    position: absolute;
    top: 0;
    display: block;
    z-index: 2;
    width: 0;
    background: #4977b5;
    // #f9d43a;
    // #4977B5;
    border-radius: 3px;
}

#soundBtn:hover .sound-list,
#soundBtn:hover .konge {
    display: block;
}

.sound-list {
    display: none;
    position: absolute;
    width: 30px;
    height: 85px;
    bottom: 40px;
    left: -6px;
    text-align: center;
    background: black;
    border-radius: 3px;
}

.konge {
    display: none;
    position: absolute;
    width: 30px;
    height: 17px;
    bottom: 23px;
    left: -6px;
    text-align: center;
    z-index: 4;
}

.sound-number {
    position: relative;
    width: 100%;
    text-align: center;
    height: 20px;
    padding-top: 5px;
    line-height: 20px;
    font-size: 15px;
    color: white;
}

.volume {
    position: relative;
    margin: auto;
    margin-top: 5px;
    width: 7px;
    height: 50px;
    background: #4977b5;
    // aqua;
    border-radius: 2px;
    overflow: hidden;
}

.volumeBar {
    display: block;
    width: 100%;
    height: 40px;
    position: absolute;
    background-color: white;
    z-index: 2;
}

.player-list {
    position: absolute;
    padding-left: 10px;
    width: 30px;
    height: 60px;
    top: 50%;
    margin-top: -30px;
    background: url('../assets/img/annual-metting/video/list.png') -10px 0px rgba(0, 0, 0, 0.1);
    background-size: cover;
    transition: opacity 0.3s;
}

.player-list-on {
    opacity: 1;
}

.player-list-off {
    opacity: 0;
}

.player-list-bk {
    display: none;
    position: absolute;
    width: 160px;
    height: 250px;
    top: 50%;
    margin-top: -150px;
    background: rgba(0, 0, 0, 0.3);
}

.player-list:hover {
    background: none;
}

.player-list:hover .player-list-bk {
    display: block;
}

.player-list-head {
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 25px;
    color: white;
    background: #7c7d7f;
}

.player-list-cotent {
    height: 220px;
    width: 160px;
    overflow: hidden;
}

// .player-list-box {
//     height: 220px;
//     width: 195px;
//     text-align: center;
//     overflow: auto;
// }

// .player-list-video {
//     padding-top: 5px;
//     margin-left: -10px;
//     width: 100%;
//     height: 20px;
//     text-align: center;
//     line-height: 15px;
//     font-size: 16px;
//     color: #00f7de;
//     cursor: pointer;
//     font-weight: bolder;
//     border-bottom: 1px #60bff2 dashed;
//     transition: all 0.2s;
// }

// .player-list-video:hover {
//     height: 25px;
//     font-size: 18px;
//     line-height: 20px;
// }

.video-now,
.video-now:hover {
    height: 30px;
    font-size: 20px;
    line-height: 25px;
    background: rgba(139, 288, 255, 0.3);
    color: #6956ff;
}

footer {
    width: 100%;
    height: 30px;
    text-align: center;
    color: darkcyan;
    font-size: 20px;
    line-height: 30px;
    margin-top: 10px;
    top: 0;
}

.psVideo-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -35px;
    width: 70px;
    height: 60px;
    z-index: 30;
}

.psVideo-loading span {
    display: inline-block;
    width: 10px;
    height: 0;
    position: absolute;
    background: rgba(96, 191, 242, 0.53);
    top: 50%;
    margin-top: 0;
    left: 0;
}

.psVideo-loading span:nth-child(1) {
    animation: span1 1s linear infinite;
}

.psVideo-loading span:nth-child(2) {
    height: 30px;
    top: 50%;
    margin-top: -15px;
    margin-left: 20px;
    animation: span2 1s linear infinite;
}

.psVideo-loading span:nth-child(3) {
    height: 60px;
    top: 50%;
    margin-top: -30px;
    margin-left: 40px;
    animation: span3 1s linear infinite;
}

@keyframes span1 {
    0% {
        height: 0;
        margin-top: 0;
    }
    50% {
        height: 50px;
        margin-top: -25px;
    }
    100% {
        height: 0;
        margin-top: 0;
    }
}

@keyframes span2 {
    0% {
        height: 30px;
        margin-top: -15px;
    }
    25% {
        height: 60px;
        margin-top: -30px;
    }
    50% {
        height: 30px;
        margin-top: -15px;
    }
    75% {
        height: 0;
        margin-top: 0;
    }
    100% {
        height: 30px;
        margin-top: -15px;
    }
}

@keyframes span3 {
    0% {
        height: 60px;
        margin-top: -30px;
    }
    50% {
        height: 0;
        margin-top: 0;
    }
    100% {
        height: 60px;
        margin-top: -30px;
    }
}
</style>