<template>
    <div class="memory-swiper-video-component">
        <div id="demo0">

            <table align="left" cellspace="0" border="0" cellpadding="0">

                <tr  @click="openVideo">

                    <td id="demo1" valign="top">

                        <table border="0" cellpadding="0" cellspacing="0">

                            <tr>

                                <td>

                                    <table cellSpacing="0" border="0" cellPadding="7">

                                        <tbody>

                                            <tr align="middle">
                                                <td style="padding: 0 7px;" v-for="item in videoMemoryList" :key="item.id" v-if="item.video && item.videoCover">

                                                    <div style="position: relative;" @click.stop="goDetail(item.video)"  class="video-player">
                                                        <!-- <img :src="item.videoCover"
                                                            style="width: 530px; height: 293px;" /> -->
                                                        <video :src="item.video" :poster="item.videoCover"
                                                            style="width: 530px; height: 293px;" ></video>

                                                        <!-- <video  :src="urlBigVideo"  controls="controls"  @click.stop="isBigVideo = true">
                                                         您的浏览器不支持 video 标签。
                                                        </video> -->
                                                        <div
                                                            style="position: absolute; top: 88px; left: 50%;     transform: translateX(-50%);">
                                                            <span
                                                                style="display: block; padding: 7px 12px; background: rgba(0, 0, 0, 0.6); margin-bottom: 19px;border-radius: 13px; font-size: 14px;" v-if="item.duration">
                                                                {{item.duration}}
                                                            </span>
                                                            <img src="../assets/img/annual-metting/icon-play.png"
                                                                style="width: 56px; height: 56px;" alt="">
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td style="padding: 0 7px;">

                                                    <div style="position: relative;">
                                                        <img src="../assets/img/annual-metting/005.jpg"
                                                            style="width: 530px; height: 293px;" />
                                                        <div
                                                            style="position: absolute; top: 88px; left: 50%;     transform: translateX(-50%);">
                                                            <span
                                                                style="display: block; padding: 7px 12px; background: rgba(0, 0, 0, 0.6); margin-bottom: 19px;">00:04:56</span>
                                                            <img src="../assets/img/annual-metting/icon-play.png"
                                                                style="width: 56px; height: 56px;" alt=""></div>
                                                    </div>
                                                </td>
                                                <td style="padding: 0 7px;">

                                                    <div style="position: relative;">
                                                        <img src="../assets/img/annual-metting/005.jpg"
                                                            style="width: 530px; height: 293px;" />
                                                        <div
                                                            style="position: absolute; top: 88px; left: 50%;     transform: translateX(-50%);">
                                                            <span
                                                                style="display: block; padding: 7px 12px; background: rgba(0, 0, 0, 0.6); margin-bottom: 19px;">00:04:56</span>
                                                            <img src="../assets/img/annual-metting/icon-play.png"
                                                                style="width: 56px; height: 56px;" alt="">
                                                        </div>

                                                    </div>
                                                </td>
                                                <td style="padding: 0 7px;">

                                                    <div style="position: relative;">
                                                        <img src="../assets/img/annual-metting/005.jpg"
                                                            style="width: 530px; height: 293px;" />
                                                        <div
                                                            style="position: absolute; top: 88px; left: 50%;     transform: translateX(-50%);">
                                                            <span
                                                                style="display: block; padding: 7px 12px; background: rgba(0, 0, 0, 0.6); margin-bottom: 19px;">00:04:56</span>
                                                            <img src="../assets/img/annual-metting/icon-play.png"
                                                                style="width: 56px; height: 56px;" alt="">
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style="padding: 0 2px 0 3.5px;">

                                                    <div style="position: relative;">
                                                        <img src="../assets/img/annual-metting/005.jpg"
                                                            style="width: 530px; height: 293px;" />
                                                        <div
                                                            style="position: absolute; top: 88px; left: 50%;     transform: translateX(-50%);">
                                                            <span
                                                                style="display: block; padding: 7px 12px; background: rgba(0, 0, 0, 0.6); margin-bottom: 19px;">00:04:56</span>
                                                            <img src="../assets/img/annual-metting/icon-play.png"
                                                                style="width: 56px; height: 56px;" alt="">
                                                        </div>
                                                    </div>
                                                </td> -->

                                            </tr>

                                        </tbody>

                                    </table>

                                </td>

                            </tr>

                        </table>

                    </td>

                    <td id="demo2" valign="top"></td>

                </tr>

            </table>

        </div>
    </div>
</template>

<script>
export default {
    props: ['videoMemoryData'],
    data() {
        return {
            videoMemoryList: []
        }
    },
    watch: {
        videoMemoryData(newVal, oldVal) {
            this.videoMemoryList = newVal
            setTimeout(this.init, 1500)
        }
    },
    mounted() {},
    methods: {
        openVideo (e) {
            //console.log('e.currentTarget', e.currentTarget, e.currentTarget.innerHTML)
            //console.log('e.target.currentSrc', e, e.target.children, e.target.innerHTML)
            // this.$emit('openImg', e.target.currentSrc, e.target.parentNode.children[1].innerHTML)
            this.$emit('openVideo', e.target.currentSrc)
        },
        goDetail (u) {
            this.$emit('openVideo', u)
        },
        init() {
            var speed = 20

            //数值越大速度越慢

            demo2.innerHTML = demo1.innerHTML

            function Marquee() {
                if (demo2.offsetWidth - demo0.scrollLeft <= 0) demo0.scrollLeft -= demo1.offsetWidth
                else {
                    demo0.scrollLeft++
                }
            }

            let MyMar = setInterval(Marquee, speed)

            demo0.onmouseover = function() {
                clearInterval(MyMar)
            }
            demo0.onmouseout = function() {
                MyMar = setInterval(Marquee, speed)
            }
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.memory-swiper-video-component {
    height: 296px;
    width: 100%;
    position: relative;
    #demo0 {
        overflow: hidden;
        height: 100%;
        width: 100%;
        color: #ffffff;
        /* margin: 0 auto; */
        position: absolute;
        top: 0;
        left: 0;
        // transform: translate(-50%, -50%);
    }
    table,
    tbody {
        margin: 0;
        padding: 0;
    }
    tr {
        margin: 0;
        padding: 0;
    }
    td {
        padding: 0;
    }
}
</style>