<template>
    <div class="word-cloud-year-component">
        <div id="wordCloud" style="width: 1200px; height: 375px"></div>
    </div>
</template>

<script>
// https://www.npmjs.com/package/echarts-wordcloud

export default {
    props: [ 'cloudList' ],
    data() {
        return {
            list: [
                // {
                //     value: '50',
                //     name: '华为',
                //     textStyle: {
                //         shadowBlur: 4,
                //         shadowColor: '#ECEFFF',
                //         shadowOffsetY: 14,
                //         color: '#B7AFAF'
                //     }
                // }, // 50
                // { value: '30', name: 'VIVO' },
                // { value: '29', name: 'OPPO' },
                // { value: '28', name: 'HONOR' },
                // { value: '27', name: '红米' },
                // { value: '26', name: '小米' },
                // { value: '25', name: '美图' },
                // { value: '24', name: 'ONEPLUS' },
                // { value: '23', name: '魅族' },
                // { value: '22', name: '红手指' },
                // { value: '21', name: 'SAMSUNG' },
                // { value: '20', name: '金立' },
                // { value: '16', name: 'BLACKBERRY' },
                // { value: '15', name: '诺基亚' },
                // { value: '14', name: '锤子' },
                // { value: '13', name: '大疆' },
                // { value: '12', name: '361' },
                // { value: '11', name: '摩托罗拉' },
                // { value: '10', name: '联想' },
                // { value: '9', name: '玩家国度' }
            ]
        }
    },
    watch: {
        cloudList (newVal, oldVal) {
            this.list = newVal
            this.init()
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            var myChart = this.$echarts.init(document.getElementById('wordCloud'))
            //温馨提示：image 选取有严格要求不可过大；，否则firefox不兼容  iconfont上面的图标可以
            // var mask = new Image()
            // mask.src = this.cloudData.image

            // maskImage.onload = function(){
            var option = {
                backgroundColor: '#f5f3f3',
                tooltip: {
                    show: true,
                    borderWidth: 1,
                    padding: [6, 15, 6, 15],
                    // backgroundColor: 'rgba(255, 255, 255, .9)',
                    backgroundColor: '#f5f3f3',
                    textStyle: {
                        // color: 'hotpink',
                        lineHeight: 22
                    },
                    trigger: 'item',
                    enterable: true,
                    triggerOn: 'mousemove',
                    // trigger: 'axis',
                    confine: true,
                    // position: 'top',
                    formatter(arg) {
                        // console.log('arg', arg)
                        return arg.marker + ' ' + arg.name
                    }
                    
                },
                // tooltip: {
                //     show: true,
                //     trigger: 'item',
                //     enterable: true,
                //     triggerOn: 'mousemove',
                //     // trigger: 'axis',
                //     confine: true,
                //     // position: 'top',
                //     formatter(arg) {
                //         console.log('arg', arg)
                //         return arg.marker + ' ' + arg.name
                //     }
                // },
                series: [
                    {
                        type: 'wordCloud',
                        // The shape of the "cloud" to draw. Can be any polar equation represented as a
                        // callback function, or a keyword present. Available presents are circle (default),
                        // cardioid (apple or heart shape curve, the most known polar equation), diamond (
                        // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.

                        shape: 'pentagon',

                        // A silhouette image which the white area will be excluded from drawing texts.
                        // The shape option will continue to apply as the shape of the cloud to grow.

                        // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
                        // Default to be put in the center and has 75% x 80% size.

                        left: 'center',
                        top: 'center',
                        width: '100%',
                        height: '100%',
                        right: null,
                        bottom: null,

                        // Text size range which the value in data will be mapped to.
                        // Default to have minimum 12px and maximum 60px size.

                        sizeRange: [12, 30],

                        // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45

                        rotationRange: [0, 0],
                        rotationStep: 0,

                        // size of the grid in pixels for marking the availability of the canvas
                        // the larger the grid size, the bigger the gap between words.

                        gridSize: 25,

                        // set to true to allow word being draw partly outside of the canvas.
                        // Allow word bigger than the size of the canvas to be drawn
                        drawOutOfBound: false,

                        // If perform layout animation.
                        // NOTE disable it will lead to UI blocking when there is lots of words.
                        layoutAnimation: true,

                        // Global text style
                        textStyle: {
                            fontFamily: 'PingFangSC-Semibold',
                            fontWeight: 600,
                            color: function() {
                                // Random color
                                var x = Math.random()
                                if (x < 0.6) {
                                    x = 0.6
                                }
                                return 'rgba(196, 34, 34, ' + x + ')'
                            }
                            // color: function(params) {
                            //     // let colors = ['#B3A9AC', '#B0A6A8', '#B5ACAD', '#B0A7A8', '#B0A8A9', '#B0A8AA', '#B3ABAB', '#B3A9AB', '#B2A9AA', '#B3A9AC', '#B0A6A8']
                            //     // return colors[parseInt(Math.random() * 10)]
                            //     return 'rgba(' +
                            //                 Math.round(Math.random() * 255) +
                            //                 ', ' + Math.round(Math.random() * 255) +
                            //                 ', ' + Math.round(Math.random() * 255) + ', 0.8)'
                            // }
                        },
                        emphasis: {
                            // focus: 'none'
                        },

                        // Data is an array. Each array item must have name and value property.
                        data: this.list
                    }
                ]
            }
            myChart.setOption(option, true)
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
</style>