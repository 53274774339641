<template>
    <div class="annual-meeting-detail-page">
        <HeaderAnnualMeeting />
        <div class="annual-meeting-detail">
            <div class="banner">
                <h4>{{annualObj.title}}</h4>
                <h5>{{annualObj.subTitle}}</h5>
                <!-- <h6 @click="goBack">返回年会专区</h6> -->
            </div>
            <div class="crumbs">
                <h6 @click="goBack">
                    << 返回年会专区</h6> </div> <div class="summary-wrap">
                        <div class="com-wrap">
                            <img src="../assets/img/annual-metting/title11.png" alt="" />
                        </div>
                        <div class="summary wid1200">
                            <p>{{annualObj.summary}}
                            </p>
                            <div class="video-wrap">
                                <video-player :videoCover="videoCover" :videoPath="videoPath" />
                            </div>
                        </div>
            </div>
            <div class="agenda-review-wrap">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title22.png" alt="" />
                </div>
                <div class="agenda-review">
                    <!-- v-show="timeAxisData && timeAxisData.length>0" -->
                    <div class="time-line">
                        <div class="left-btn"></div>
                        <div class="right-btn"></div>
                        <div class="time-box">
                            <ul>

                                <!-- <li class="active"  v-for="item in timeAxisData" :key="item.id">
                    <div>
                        <div class="point"></div>
                        
                    </div>
                    <div>
                        <p>
                            <a :title="item.abstractCn">{{item.abstractCn}}</a>
                        </p>
                    </div>
                    

                    <h3>{{item.eventTime}}</h3>

                </li> -->
                                <!-- active-review -->
                                <li :class="[ activeReviewId == item.id? 'active-review': '' ]"
                                    v-for="item in reviewData" :key="item.id" @click="switchReviewId(item.id)">
                                    <div class="point-wrap">
                                        <div class="point"></div>
                                    </div>
                                    <div class="date">{{item.releaseDate}}</div>
                                    <div class="info">
                                        <div class="time">{{item.timeSlot}}</div>
                                        <a class="history-title" :title="item.title">{{item.title}}</a>
                                        <!-- <h5>{{item.title}}</h5> -->
                                        <a class="abstract" :title="item.abstractCn1" v-html="item.abstractCn"
                                            style="text-align: left;">
                                            <!-- {{item.abstractCn}} -->
                                        </a>
                                        <!-- <p v-html="item.abstractCn"></p> -->
                                        <!-- <p>主题:学术集刊发展机制与创新</p> -->
                                    </div>
                                </li>
                                <div class="clear" style="clear: both;"></div>
                                <!-- <li>
                                    <div class="point-wrap">
                                        <div class="point"></div>
                                    </div>
                                    <div class="info">
                                        <div class="time">14:00-16:30</div>
                                        <h5>分组讨论</h5>
                                        <p>主持人：各召集人和主持人</p>
                                        <p>主题:学术集刊发展机制与创新</p>
                                    </div>

                                </li>
                                <li>
                                    <div class="point-wrap">
                                        <div class="point"></div>
                                    </div>
                                    <div class="info">
                                        <div class="time">14:00-16:30</div>
                                        <h5>分组讨论</h5>
                                        <p>主持人：各召集人和主持人</p>
                                        <p>主题:学术集刊发展机制与创新</p>
                                    </div>

                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="swiper-wrap" v-show="reviewSwiperData.length>0">
                    <div class="wid1200">
                        <el-carousel indicator-position="outside" :interval="3000">
                            <el-carousel-item v-for="item in reviewSwiperData" :key="item+999999">

                                <ul>
                                    <li v-for="i in item" :key="i.id">
                                        <h6>{{i.title}}</h6>
                                        <p v-html="i.abstractCn"></p>
                                        <!-- <p>主题介绍</p>
                                        <p>本组将围绕学术集刊的社会功能与核心竞争力展开讨论，参会人讲以实际参与学术集刊的工作经验进行描述。</p> -->
                                    </li>
                                    <!-- <li>
                                        <h6>学术集刊的社会功能与核心竞争力</h6>
                                        <p>主持人：陈陈陈</p>
                                        <p>主题介绍</p>
                                        <p>本组将围绕学术集刊的社会功能与核心竞争力展开讨论，参会人讲以实际参与学术集刊的工作经验进行描述。</p>
                                    </li>
                                    <li>
                                        <h6>学术集刊的社会功能与核心竞争力</h6>
                                        <p>主持人：陈陈陈</p>
                                        <p>主题介绍</p>
                                        <p>本组将围绕学术集刊的社会功能与核心竞争力展开讨论，参会人讲以实际参与学术集刊的工作经验进行描述。</p>
                                    </li> -->
                                </ul>
                            </el-carousel-item>
                        </el-carousel>
                    </div>

                </div>

            </div>

            <div class="quotes-wrap">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title33.png" alt="" />
                </div>
                <div class="quotes">
                    <QuoteSwiper :areaResearchData="quoteData" />
                </div>
            </div>
            <div class="word-clould-wrap wid1200">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title44.png" alt="" />
                </div>
                <!-- <AnnualWordCloud :cloudList="cloudList" /> -->
                <div class="wordcloud">
                    <!-- <WordCloudYear :cloudList="cloudList" /> -->
                    <img :src="annualObj.wordCloud + '?w=1100'" alt="" />
                </div>
            </div>

            <div class="books-wrap wid1200">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title55.png" alt="" />
                </div>
                <div class="books">

                    <ul v-for="(item, ind) in bookData" :key="ind+999999">
                        <li v-for="i in item" :key="i.id" @click="goBookDetail(i)">
                            <img :src="i.imgPath + '?w=216&h=307'" alt="" />
                            <a :title="'《'+i.name+ '》'">《{{i.name}}》</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="media-report-wrap">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title66.png" alt="" />
                </div>
                <div class="media-report">
                    <img id="reportBg" src="../assets/img/annual-metting/bg-news2.png" alt="">
                    <div class="media-report-content wid1200">
                        <ul>
                            <li v-for="item in reportData" :key="item.id">
                                <a :title="item.title" :href="item.url" target="_blank">{{item.title}}</a>
                                <span>{{item.releaseDate}}</span>
                                <!-- updateTime
                                createTime -->
                            </li>
                            <!-- <li>
                                <a title="教育信息速报：第九届人文社科集刊年会聚焦高质量发展">教育信息速报：第九届人文社科集刊年会聚焦高质量发展</a>
                                <span>2021-09</span>
                            </li> -->
                        </ul>
                        <div class="pagination-wrap" style="display: flex; justify-content: center; margin-top: 22px;">
                            <el-pagination @current-change="handleNewsChange" :current-page="newsNum" :page-size="8"
                                layout="prev, pager, next" :total="totalNews">
                                <!-- total, prev, pager, next, jumper -->
                            </el-pagination>
                        </div>
                    </div>
                </div>

            </div>

            <div class="memory-wrap">
                <div class="com-wrap">
                    <img src="../assets/img/annual-metting/title77.png" alt="" />
                </div>
                <div class="memory">
                    <MemorySwiperVideo v-show="videoMemoryData.length>0" :videoMemoryData="videoMemoryData"
                        @openVideo="openVideo" />
                    <MemorySwiperImg v-show="imgMemoryData.length>0" :imgMemoryData="imgMemoryData"
                        @openImg="openImg" />
                </div>
            </div>

        </div>
        <AnnualMeetingFooter />
        <el-dialog :visible.sync="isBigImg">
            <div style="width: 100%; padding: 20px 0;" @click.stop="isBigImg = false">
                <img style="display: block; margin: 0 auto;" :src="urlBigImg" @click.stop="isBigImg = true" alt="" />
                <h6 style="text-align: center; font-size: 14px; margin: 10px 0; color: #fff;"
                    @click.stop="isBigImg = true">{{nameBigImg}}</h6>
                <p style="padding: 0 20px; text-align: left; font-size: 14px;  color: #fff;" v-html="abstractBigImg"
                    @click.stop="isBigImg = true"></p>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="isBigVideo">
            <div style="width: 100%;display: flex; align-items: center; justify-content: center;"
                @click="isBigVideo = false">
                <!-- <video  :src="urlBigVideo" @click.stop="isBigVideo = true" >
                   <source :src="urlBigVideo" type="video/mp4">
               </video> -->
                <video :src="urlBigVideo" controls="controls" @click.stop="isBigVideo = true">
                    您的浏览器不支持 video 标签。
                </video>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import AnnualMeetingFooter from '../components/AnnualMeetingFooter'
import QuoteSwiper from '../components/AnnualQuoteSwiper'
import AnnualWordCloud from '../components/AnnualWordCloud'
import MemorySwiperVideo from '../components/MemorySwiperVideo'
import MemorySwiperImg from '../components/MemorySwiperImg'
import VideoPlayer from '../components/AnnualVideo'

export default {
    components: { HeaderAnnualMeeting, AnnualMeetingFooter, QuoteSwiper, AnnualWordCloud, MemorySwiperVideo, MemorySwiperImg, VideoPlayer },
    data() {
        return {
            pageName: '年会详情页',
            quoteData: [],
            videoPath: '',
            annualObj: {},
            videoCover: '',
            cloudList: [],
            year: '',
            bookData: [],
            reportData: [],
            memoryData: [],
            videoMemoryData: [],
            imgMemoryData: [],
            reviewData: [],
            activeReviewId: '',
            reviewSwiperData: [],
            isBigImg: false,
            urlBigImg: '',
            urlBigVideo: '',
            isBigVideo: false,
            nameBigImg: '',
            abstractBigImg: '',
            totalNews: 0,
            newsNum: 1
        }
    },
    mounted() {
        var x = document.body.clientWidth - (document.body.clientWidth - 1200) / 2
        document.getElementById('reportBg').style.width = x + 'px'

        var _this = this
        setTimeout(function() {
            var timeLength = $('.time-box li').length,
                timeliWidth = $('.time-box li').outerWidth()

            var index = 0

            if (4 - Math.round(timeLength % 4) != 4) {
                timeLength = timeLength + (4 - Math.round(timeLength % 4))
            }
            $('.time-box ul').width(timeLength * timeliWidth)

            function slideOne(i) {
                var scrollVal = i * timeliWidth //每次切换的数量
                $('.time-box')
                    .stop()
                    .animate(
                        {
                            scrollLeft: scrollVal
                        },
                        300
                    )
            }

            $('.right-btn').click(function() {
                // index = index >= timeLength - 4 ? 0 : index + 4
                index = index >= timeLength - 4 ? index : index + 4
                slideOne(index)
            })

            $('.left-btn').click(function() {
                index = index <= 0 ? index : index - 4
                slideOne(index)
            })
        }, 1000)
    },
    methods: {
        handleNewsChange(val) {
            this.newsNum = val
            // this.getList('MEETINGREPORT')
            this.getList('MEETINGREPORT')
        },
        goBack() {
            this.$router.back(-1)
        },
        goBookDetail(item) {
            this.$router.push({ path: '/c/c', query: { id: item.id, pN: this.pageName } })
        },
        openVideo(url) {
            // console.log(url)
            this.urlBigVideo = url
            this.isBigVideo = true
        },
        openImg(url, id) {
            if (url) {
                this.urlBigImg = url
                this.imgMemoryData.forEach(e => {
                    if (e.id == id) {
                        this.nameBigImg = e.title
                        this.abstractBigImg = e.abstractCn
                    }
                })

                this.isBigImg = true
            } else {
                return
            }
        },
        switchReviewId(id) {
            if (this.activeReviewId == id) {
                return
            } else {
                this.activeReviewId = id
                this.getReview()
            }
        },
        getReview() {
            // /api/annualMeeting/resourceList?meetingId=(年会id)&resourceType=(资源类型)&parentId=(议程回顾id)
            let _this = this
            _this
                .$http({
                    method: 'get',
                    url: '/admin/api/annualMeeting/resourceList',
                    params: {
                        meetingId: _this.$route.query.id,
                        resourceType: 'MEETINGNEWS',
                        parentId: _this.activeReviewId
                    }
                })
                .then(res => {
                    if (res.data.data.rows.length != 0) {
                        let data = res.data.data.rows
                        var result = []
                        for (var i = 0, len = data.length; i < len; i += 3) {
                            result.push(data.slice(i, i + 3))
                        }
                        _this.reviewSwiperData = result
                    } else {
                        _this.reviewSwiperData = []
                        return
                    }
                })
        },
        getData() {
            let _this = this
            return new Promise(function(resolve, reject) {
                _this
                    .$http({
                        method: 'get',
                        url: '/admin/api/annualMeeting/meetingDetail?id=' + _this.$route.query.id
                    })
                    .then(res => {
                        _this.annualObj = res.data
                        _this.videoCover = res.data.videoCover + '?w=1200&h=480'
                        _this.videoPath = res.data.video
                        // _this.cloudList = res.data.cloudList
                        _this.year = res.data.year
                        resolve(res.data)
                        // videoCover
                    })
            })
        },
        getList(type) {
            let _this = this
            var xx = {
                meetingId: _this.annualId,
                resourceType: type
            }
            if (type == 'MEETINGREPORT') {
                xx = {
                    meetingId: _this.annualId,
                    resourceType: type,
                    pageSize: 7,
                    pageNum: _this.newsNum
                }
            }
            return new Promise(function(resolve, reject) {
                _this
                    .$http({
                        method: 'get',
                        url: '/admin/api/annualMeeting/resourceList',
                        params: xx
                    })
                    .then(res => {
                        if (type == 'MEETINGREVIEW') {
                            //议程回顾
                            _this.reviewData = []
                            var w = res.data.data.rows
                            // console.log('w', w)
                            w.forEach(e => {
                                if (e.abstractCn) {
                                    e.abstractCn1 = e.abstractCn
                                        .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
                                        .replace(/<br\/?>/gi, '\n')
                                        .replace(/<[^>/]+>/g, '')
                                        .replace(/(\n)?<\/([^>]+)>/g, '')
                                        .replace(/\u00a0/g, ' ')
                                        .replace(/&nbsp;/g, ' ')
                                        .replace(/<\/?(img)[^>]*>/gi, '')
                                        .replace(/&amp;/g, '&')
                                        .replace(/&lt;/g, '<')
                                        .replace(/&gt;/g, '>')
                                        .replace(/&#39;/g, "'")
                                        .replace(/&quot;/g, '"')
                                        .replace(/<\/?.+?>/g, '')
                                } else {
                                    e.abstractCn1 = ''
                                }
                            })
                            _this.reviewData = w
                        } else if (type == 'MEETINGQUOTATIONS') {
                            //精彩语录
                            _this.quoteData = []
                            var x = res.data.data.rows
                            x.forEach(e => {
                                e.abstractCn = e.abstractCn
                                    .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
                                    .replace(/<br\/?>/gi, '\n')
                                    .replace(/<[^>/]+>/g, '')
                                    .replace(/(\n)?<\/([^>]+)>/g, '')
                                    .replace(/\u00a0/g, ' ')
                                    .replace(/&nbsp;/g, ' ')
                                    .replace(/<\/?(img)[^>]*>/gi, '')
                                    .replace(/&amp;/g, '&')
                                    .replace(/&lt;/g, '<')
                                    .replace(/&gt;/g, '>')
                                    .replace(/&#39;/g, "'")
                                    .replace(/&quot;/g, '"')
                                    .replace(/<\/?.+?>/g, '')
                            })
                            _this.quoteData = x
                        } else if (type == 'MEETINGREPORT') {
                            //媒体报道
                            _this.reportData = res.data.data.rows
                            _this.totalNews = res.data.data.total
                            // console.log('_this.reportData', _this.reportData)
                        } else if (type == 'MEETINGVIDEO') {
                            //精彩留念
                            _this.videoMemoryData = res.data.data.rows
                        } else if (type == 'MEETINGPICTURE') {
                            _this.imgMemoryData = res.data.data.rows
                        }
                        // if (type == 'MEETINGREPORT') {
                        //     // 年会报道
                        //     _this.reportData = res.data
                        // } else if (type == 'MEETINGPERSON') {
                        //     // 年会人物
                        //     var xx  = res.data
                        //     var result = []
                        //     for(var i=0,len=xx.length;i<len;i+=7){
                        //         result.push(xx.slice(i,i+7));
                        //     }

                        //     _this.expertData = result

                        // } else if (type == 'MEETINGEVENTS') {
                        //     // 大事纪
                        //     _this.eventData = res.data
                        //     _this.eventData.forEach((e, ind) => {
                        //         e.abstractCn = e.abstractCn.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n').replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '').replace(/(\n)?<\/([^>]+)>/g, '').replace(/\u00a0/g, ' ').replace(/&nbsp;/g, ' ').replace(/<\/?(img)[^>]*>/gi, '').replace(/&amp;/g,"&").replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(/&#39;/g,"\'").replace(/&quot;/g,"\"").replace(/<\/?.+?>/g,"")
                        //     })
                        // } else {
                        // }
                        resolve(res.data)
                    })
            })
        },
        getBook() {
            let _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/cp/list',
                params: {
                    classifyId: '',
                    cnki: '',
                    cssci: '',
                    cycle: '',
                    excellentDate: _this.year,
                    directoriesDate: '',
                    name: '',
                    pageNum: 1,
                    pageSize: 1000,
                    meetingId: _this.annualId
                }
            }).then(res => {
                if (res.data.code == 0) {
                    var curA = res.data.data.rows
                    var result1 = []
                    for (var i = 0, len = curA.length; i < len; i += 5) {
                        result1.push(curA.slice(i, i + 5))
                    }
                    _this.bookData = result1
                }
            })
        }
    },
    created() {
        let _this = this
        this.annualId = this.$route.query.id
        this.getData().then(res => {
            _this.getBook() //优秀集刊
        })
        //议程回顾
        this.getList('MEETINGREVIEW').then(res => {
            //精彩语录
            this.getList('MEETINGQUOTATIONS').then(res => {
                //年会报道
                this.getList('MEETINGREPORT').then(res => {
                    //精彩留念视频
                    this.getList('MEETINGVIDEO').then(res => {
                        //精彩留念图片
                        this.getList('MEETINGPICTURE')
                    })
                })
            })
        })
    }
}
</script>

<style lang="scss" scoped>
@mixin ellipse1 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.annual-meeting-detail-page {
    .annual-meeting-detail {
        .banner {
            width: 100%;
            height: 307px;
            background: url(../assets/img/annual-metting/bg-review-banner.png);
            background-size: 100% 100%;
            box-shadow: 0 0px 10px rgba(45, 50, 55, 0.2);
            h4 {
                // padding-top: 90px;
                padding-top: 75px;
                font-size: 36px;
                font-family: Source Han Serif CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 35px;
                text-shadow: 0px 2px 4px rgba(156, 7, 23, 0.9);
                margin-bottom: 42px;
                text-align: center;
            }
            h5 {
                text-align: center;
                font-size: 46px;
                font-family: Source Han Serif CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 46px;
                text-shadow: 0px 2px 4px rgba(156, 7, 23, 0.9);
            }
            h6 {
                width: 140px;
                font-size: 16px;
                line-height: 31px;
                border: 1px solid #fff;
                color: #fff;
                background: rgba(255, 255, 255, 0);
                margin: 0 auto;
                border-radius: 20px;
                text-align: center;
                margin-top: 18px;
                cursor: pointer;
            }
        }
        .crumbs {
            h6 {
                width: 1200px;
                margin: 0 auto;
                font-size: 15px;
                line-height: 20px;
                margin-top: 14px;
                color: #2b221b;
                cursor: pointer;
                // #2b221b
            }
        }
    }
    .com-wrap {
        img {
            height: 93px;
            width: auto;
            margin: 45px auto;
            display: block;
        }
    }

    .summary {
        p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2b221b;
            line-height: 29px;
            text-indent: 36px;
            margin-bottom: 20px;
            margin-top: -10px;
        }
        .video-wrap {
            video {
                width: 100%;
                height: 480px;
                // background: url(../assets/img/annual-metting/bg-review-banner.png) no-repeat;
                background-size: 100% 100%;
            }
        }
    }
    .agenda-review {
        // .fl {
        //     float: left;
        // }

        // .fr {
        //     float: right;
        // }

        .clear {
            clear: both;
        }

        .time-line {
            width: 1184px;
            margin: 0 auto;
            position: relative;
        }

        .time-line .left-btn,
        .time-line .right-btn {
            width: 20px;
            height: 20px;
            background: url(../assets/img/time-axis/Icon_Arrow_Left@2x.png) no-repeat;
            background-size: 14px 14px;
            background-position: 2px 3px;
            border: 2px solid #e7d5ba;
            border-radius: 50%;
            position: absolute;
            margin-top: -15px;
            cursor: pointer;
        }

        .time-line .left-btn {
            left: 0;
            top: 30px;
        }

        .time-line .right-btn {
            right: 0;
            top: 30px;
            transform: rotate(180deg);
        }

        // .time-line .right-btn:hover {
        //     background: url(../assets/img/time-axis/arrow-gray.png) no-repeat;
        //     // background-color: rgba(9, 92, 184, 1);
        //     transform: rotate(180deg);
        //     background-size: 16px 16px;
        //     background-position: 10px 10px;
        // }

        // .time-line .left-btn:hover {
        //     background: url(../assets/img/time-axis/arrow-gray.png) no-repeat;
        //     // background-color: rgba(9, 92, 184, 1);
        //     background-size: 16px 16px;
        //     background-position: 10px 10px;
        // }

        .time-line .time-box {
            width: 1048px;
            height: 176px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 20px;
        }

        .time-line .time-box ul {
            // overflow: hidden;
            position: relative;
            // height: 276px;
            padding-bottom: 34px;
        }

        .time-line .time-box ul:before {
            content: '';
            /* background: #d2d2d2; */
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            top: 6px;
            // background: url(../assets/img/time-axis/line.png) repeat-x;
            border-bottom: 1px solid #e7d5ba;
            background-position: left 50%;
            background-size: 1048px;
        }

        .time-line .time-box li {
            width: 262px;
            height: 122px;
            box-sizing: border-box;
            float: left;
            box-sizing: border-box;
            /* border: 1px solid #d2d2d2; */
            position: relative;
            cursor: pointer;
        }
        .time-line .time-box li .point-wrap {
            width: 240px;
            margin: 0 auto;
            .point {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                position: relative;
                background: url(../assets/img/time-axis/circle.png) no-repeat;
                background-size: 100% 100%;
                margin: 0 auto;
            }
        }
        .time-line .time-box li.active-review .point-wrap {
            .point {
                background: url(../assets/img/time-axis/point-hover.png) no-repeat;
                background-size: 100% 100%;
            }
        }

        .time-line .time-box li {
            position: relative;
            .date {
                position: absolute;
                top: -19px;
                left: 50%;
                transform: translateX(-50%);
                font-size: 16px;
                font-family: Source Han Serif CN;
                font-weight: bold;
                color: #2b221b;
                line-height: 16px;
                text-align: center;
            }
        }
        .info {
            width: 240px;
            margin: 0 auto;
            .time,
            .history-title {
                font-size: 16px;
                font-family: Source Han Serif CN;
                font-weight: bold;
                color: #2b221b;
                line-height: 16px;
                text-align: center;
                display: block;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .history-title {
                margin-top: 6px;
                margin-bottom: 18px;
            }
            a.abstract {
                display: block;
                text-align: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #6c6c6c;
                line-height: 22px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;

                // cursor: auto;
            }
        }

        // .time-line .time-box li:nth-child(2n) {
        //     margin-top: 154px;
        // }

        // .time-line .time-box li .fl {
        //     width: 16px;
        //     height: 122px;
        //     border-radius: 50%;
        //     box-sizing: border-box;
        //     margin-left: 15px;
        // }

        // .time-line .time-box li .fl .point {
        //     width: 16px;
        //     height: 16px;
        //     border-radius: 50%;
        //     position: relative;
        //     background: url(../assets/img/time-axis/circle.png) no-repeat;
        //     background-size: 100% 100%;
        // }

        // .time-line .time-box li div p {
        //     width: 224px;
        // }

        // .time-line .time-box li .fl .line {
        //     width: 1px;
        //     margin-left: 8px;
        //     height: 106px;
        //     border-left: 1px dashed rgba(9, 92, 184, 0.14);
        // }

        // .time-line .time-box li:nth-child(2n + 2) .fl {
        //     transform: rotate(180deg);
        // }

        // .time-line .time-box li:nth-child(2n + 1) .fr p {
        //     margin-top: -4px;
        // }

        // .time-line .time-box li:nth-child(2n + 2) .fr p {
        //     margin-top: 20px;
        // }

        // .time-line .time-box li .fr p {
        //     line-height: 26px;
        //     margin-left: 7px;
        //     color: #333;
        //     font-size: 14px;
        //     display: -webkit-box;
        //     overflow: hidden;
        //     white-space: normal !important;
        //     text-overflow: ellipsis;
        //     word-wrap: break-word;
        //     -webkit-line-clamp: 4;
        //     -webkit-box-orient: vertical;
        // }

        // .time-line .time-box li .fr p {
        //     a {
        //         cursor: default;
        //     }
        // }

        // .time-line .time-box li:nth-child(2n + 1) h3 {
        //     width: 262px;
        //     line-height: 32px;
        //     text-align: center;
        //     color: #fff;
        //     font-size: 14px;
        //     overflow: hidden;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        // }

        // .time-line .time-box li:nth-child(2n + 2) {
        //     position: relative;
        // }

        // .time-line .time-box li:nth-child(2n + 2) h3 {
        //     position: absolute;
        //     top: -32px;
        //     left: 0;
        //     width: 262px;
        //     line-height: 32px;
        //     text-align: center;
        //     color: #fff;
        //     font-size: 14px;
        //     overflow: hidden;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        // }

        .time-line .time-box li:hover:after,
        .time-line .time-box li.active:after {
            background: url(../assets/img/time-axis/point-hover.png) no-repeat;
        }
    }
    .swiper-wrap {
        width: 100%;
        height: 372px;
        background: #f3f1f1;
        padding-top: 39px;
        box-sizing: border-box;
        .el-carousel__item h3 {
            color: #475669;
            font-size: 18px;
            opacity: 0.75;
            line-height: 300px;
            margin: 0;
        }

        // .el-carousel__item:nth-child(2n) {
        //     background-color: #99a9bf;
        // }

        // .el-carousel__item:nth-child(2n + 1) {
        //     background-color: #d3dce6;
        // }
    }
    .books {
        ul {
            height: 407px;
            display: flex;
            margin-bottom: 37px;
            position: relative;
            z-index: 0;
            li {
                margin-right: 30px;

                img {
                    display: block;
                    width: 216px;
                    height: 307px;
                    box-shadow: 0 14px 10px -7px rgba(51, 51, 51, 0.3);
                    margin-bottom: 74px;
                    background-color: #fff;
                    cursor: pointer;
                }
                a {
                    display: block;
                    width: 216px;
                    text-align: center;
                    color: #0d1020;
                    font-size: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
            li:last-child {
                margin-right: 0;
            }
        }
        ul::before {
            content: '';
            width: 1377px;
            height: 116px;
            background: url(../assets/img/annual-metting/img-bookshelf.png);
            background-size: 100% 100%;
            position: absolute;
            left: -88px;
            bottom: 0;
            z-index: -1;
        }
        ul:last-child {
            margin-bottom: 0;
        }
    }
    .media-report {
        width: 100%;
        height: 448px;
        // background: url(../assets/img/annual-metting/bg-news.jpg);
        background: url(../assets/img/annual-metting/bg-news4.png);
        background-repeat: repeat-x;
        background-size: auto 100%;
        margin-bottom: 80px;
        position: relative;
        z-index: 0;
        #reportBg {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            z-index: 0;
        }
        .media-report-content {
            height: 100%;
            background: rgba(13, 16, 32, 0.5);
            // padding: 19px 30px 19px 20px;
            padding: 19px 25px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            ul {
                height: 364px;
                li {
                    width: 100%;
                    height: 51px;
                    line-height: 51px;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);

                    a {
                        width: 850px;
                        height: 51px;
                        line-height: 51px;
                        display: block;
                        position: relative;
                        padding-left: 17px;
                        color: #fff;
                        font-size: 16px;

                        cursor: pointer;
                        @include ellipse1();
                    }
                    a::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 6px;
                        height: 6px;
                        background: #ffffff;
                        border-radius: 50%;
                    }
                    span {
                        display: block;
                        font-size: 14px;
                        color: #fff;
                    }
                }
                li:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
        }
    }
    .memory-wrap {
        margin-bottom: 80px;
    }

   
}
 .word-clould-wrap .wordcloud{
    width: 100%;
    padding: 55px 0;
    box-sizing: border-box;
    background: rgba(245, 243, 243, 0.5);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
        img {
            width: 1100px;
            display: block;
            // height: 200px;
        }
        
    }
</style>